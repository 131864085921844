import React, { useState } from "react";
import "../../styles/bottom_navigate.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { AiOutlineHome, AiOutlineShoppingCart } from "react-icons/ai";
import { BsSuitHeart, BsPersonCircle } from "react-icons/bs";

const MobileBottomNavbar = () => {
  const [value, setValue] = useState('recents');
  const navigate = useNavigate();

  const cartItems = useSelector((state) => state.cart.cartItems);

  const navigateToCart = () => {
    navigate("/checkout");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="navbar">
      <Link to={"/"}>
        <div style={{ fontSize: "15px" }}>
          <i className="bi bi-shop"></i>
        </div>
        <div>Home</div>
      </Link>
      <Link to={"/product/wishlist"}>
        <div style={{ fontSize: "15px" }}>
          <i className="bi bi-heart" />
        </div>
        <div>Wish List</div>
      </Link>
      <a href="javascript:void(0)" onClick={navigateToCart} style={{ position: "relative" }}>
        <div style={{ fontSize: "15px" }}>
          <i className="bi bi-cart-plus-fill" />
          {cartItems.length > 0 && (
            <span className="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" style={{ padding: "5px", fontSize: "10px" }}>
              {cartItems.length}
            </span>
          )}
        </div>
        <div>Cart</div>
      </a>
      <Link to={"/user/deshboard/1"}>
        <div style={{ fontSize: "15px" }}>
          <i className="bi bi-person-circle" />
        </div>
        <div>Account</div>
      </Link>
    </div>
  );
}

export default MobileBottomNavbar;
