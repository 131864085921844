import React, { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

// import Helmet from '../components/Helmet/Helmet';
import '../styles/home.css';
import { Helmet } from "react-helmet";


import { Col, Container, Row } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux"
import ProductsList from '../components/UI/ProductsList';
import Top10Card from '../components/UI/ToptenCard';
import HeroSlider from '../components/Element/Slider/HeroSlider';
import Categories from '../components/Categories/Categories';
import serviceData from '../assets/data/serviceData';
import ProductCard from '../components/UI/ProductCard';
import axiosClient from '../axios-client';
import { getCurrencydata, shippingCost } from '../redux/slices/settingSlice';
import GeneralProductList from '../components/UI/GeneralProductList';
import { useQuery } from 'react-query';
import CategoriesBanner from '../components/UI/CategoriesBanner';
import { Skeleton } from '@mui/material';
import addBanner from '../assets/images/home-v5-banner.png'
import catBanner1 from '../assets/images/cat-banner-1.jpg'
import catBanner2 from '../assets/images/cat-banner-2.jpg'
import catBanner3 from '../assets/images/cat-banner-3.jpg'
import Main from './Main';
import Categoris from './Categoris';



const Home = () => {

  const [trendingProducts, settrendingProducts] = useState([]);
  const [bestSalesProducts, setbestSalesProducts] = useState([]);
  const [popularProdutcs, setpopularProdutcs] = useState([]);
  const [newArrivalProduct, setnewArrivalProduct] = useState([]);
  const [isrender, setIsRender] = useState(true);
  const [offerBanner, setOfferBanner] = useState([]);
  const [category, setCategoryList] = useState([]);
  const[home, setHome] = useState('');

  const year = new Date().getFullYear();
  const dispatch = useDispatch();

  const basepath = useSelector((state) => state.setting.basepath);
  // const bannerlink = useQuery('bannerlink', async () => await axiosClient.get('featured/link/list').then(({ data }) => data));

  // const offerBannerdata = useQuery('myDatas', async () => await axiosClient.get('offer/banner').then(({ data }) => data));
  const categoryListdata = useQuery('category', async () => await axiosClient.get('product/category').then(({ data }) => data));
  // console.log(categoryListdata, "categoryListdata")
  // const trendingListdata = useQuery('trending', async () => await axiosClient.get('home/trending/product/get').then(({ data }) => data.data));
  // const bestsellListdata = useQuery('bestsell', async () => await axiosClient.get('home/best/sell/product').then(({ data }) => data));
  // const newarrivaldata = useQuery('newarrival', async () => await axiosClient.get('home/new/arrival/product').then(({ data }) => data));
  // const populardata = useQuery('popular', async () => await axiosClient.get('home/popular/product/get').then(({ data }) => data));
  // const popularcategory = useQuery('popularCategory', async () => await axiosClient.get('product/popular/category').then(({ data }) => data));
  // const popularbrand = useQuery('popularBrand', async () => await axiosClient.get('product/top/brand').then(({ data }) => data));
  // const eventbuilder = useQuery('eventbuilder', async () => await axiosClient.post('/eventbuilder').then(({ data }) => data));


  // useQuery('currency', () => dispatch(getCurrencydata()));

  useEffect(() => {
    axiosClient.get('seo/home').then(({ data }) => {
      setHome(data.data);
    });

  }, [])



  return (
    <>
    <Helmet >
       <title>DJ Peoples {home && home.seo_title}</title>
        <meta name="description" content={home && home.seo_meta_desc} />
        <meta name="keywords" content={home && home.seo_meta_name} />
       
    </Helmet>
      <Main  />
      <Categoris categoryListdata={categoryListdata} />
    </>
  )
}

export default Home;