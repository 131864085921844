// src/components/LogoSlider.js
import React from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Client1 from "./clients1.png"
import Client2 from "./clients2.png"
import Client3 from "./clients3.png"
import Client4 from "./clients4.png"
import Client5 from "./clients5.png"

const logos = [
    Client1,
    Client2,
    Client3,
    Client4,
    Client5,
];

const LogoSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <Box sx={{ width: "100%", margin: "auto", paddingTop: "2rem", paddingBottom: "2rem" }}>
            <h4 className="culture mb-1">Our Clients</h4>
            <Slider {...settings}>
                {logos.map((logo, index) => (
                    <Box key={index} sx={{ padding: "10px" }}>
                        <img src={logo} alt={`logo-${index}`} style={{ width: "100%" }} />
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default LogoSlider;
