import React, { useState } from 'react';
import Helmet from '../components/Helmet/Helmet';
import { Link } from 'react-router-dom';
import { useHistory, useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import '../styles/authentications.css';
import axiosClient from '../axios-client';
import { useSelector, useDispatch } from "react-redux";
import { userAction } from '../redux/slices/userSlice';
import { toast } from "react-toastify";
import { getWishcount } from '../redux/slices/settingSlice';
import Modal from 'react-bootstrap/Modal';



const Login = () => {
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const islogin = useSelector((state) => state.user.islogin);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [resetPass, setResetPass] = useState();
  const [password, setPassword] = useState();
  const [loginError, setloginError] = useState(false);
  const navigate = useNavigate();
  const handleLoginSubmit = e => {
    e.preventDefault();
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = () => {
    const info = {
      email: email,
      password: password,
    }
    axiosClient.post("login", info).then(({ data }) => {

      if (data.status == 422) {
        setloginError(true)
        toast.error(data.msg, { theme: "colored" });
      }
      if (data.status == 200) {
        let infodata = {
          token: data.token,
          userInfo: data.user,
        }

        dispatch(userAction.setLoginInfo(infodata));
        dispatch(getWishcount());
        toast.success('Successfully Login')

        navigate('/')
      }

    })
  }

  const resetPassword = () =>{
       try{
        const dataemail = {
        email:resetPass,
       }
    axiosClient.post("/forgot_password",dataemail).then(({ data })=>{
      if (data.status == 500) {
        toast.error(data.msg, { theme: "User does not exist, Plesase create Account" });
      }
      if (data.status == 200) {
        toast.success(data.msg, { theme: "Your Password have been sent on your Email " });
      }
      toast.success("Password sent successfuly")
      handleClose();
  
    })
  }catch(error){
      console.log(error);
    }
  }

  //console.log("resetPass",resetPass);

  return (
    <Helmet title="Login">
      <section>
        <Container>
          <Row>
            {/* <Col lg='12' className='login-feture py-5'>
                    <h3 className='fw-bold fs-4 mb-4 text-center'> Sign In </h3>
                    <div>
                      
                      <Form className = 'auth__form' onSubmit={handleLoginSubmit}>
                    
                          <FormGroup className='form__group'>
                            <Label> Email address* </Label>
                            <Input className='form-control'
                                   required 
                                   type= "email" 
                                   id='exampleEmail'name='email' 
                                   placeholder='Enter Your Name '
                                   value= {email}
                                   onChange={e=> setEmail(e.target.value)} />
                          </FormGroup>

                          <FormGroup className='form__group'>
                            <Label> Password * </Label>
                            <Input type="password" name='name'
                                   id='examplepass'
                                   required
                                   placeholder='Enter Your password'
                                   value= {password}
                                   onChange={e=> setPassword(e.target.value)} />
                          </FormGroup>
                          {
                            loginError&& <div><h5 className="loinerrtxt"> Provided Email Address or Password is Incorrect </h5> <br/></div> 
                          }
                          

                          <FormGroup className='form__group'>
                              <button onClick={submitForm} class="auth__btn"> Submit </button>
                          </FormGroup>
                          <p className='text-black'> 
                            Don't have an account ? {""}  
                            <Link className= 'text-black fw-bold' to="/Signup">  Create an account </Link>
                          </p>
                      </Form>

                  </div>
                </Col> */}

            <div className="login-page">
              <div className="login-container">
                <div className="login-image-container">
                  {/* You can replace this with an actual image */}
                  <img
                    src="https://img.freepik.com/premium-vector/illustration-sign-up-log-wireframe-idea-showcasing-various-ui-elements_1278800-10883.jpg?w=826"
                    alt="Login Illustration"
                    className="login-image"
                  />
                </div>
                <div className="login-form-container">
                  <div className="login-glass-effect">
                    <h2>Welcome Back</h2>
                    <form onSubmit={handleLoginSubmit}>
                      <div className="input-container">
                        <input style={{color:"#000"}} value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="Enter your email address" required />
                      </div>
                      <div className="input-container">
                        <input style={{color:"#000"}} value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder="Enter your password" required />
                      </div>
                      <button onClick={submitForm} type="submit" className="login-btn">
                        Login
                      </button>
                    </form>
                    <div className="login-options mt-4">
                      <Link to="" onClick={handleShow} className="forgot-password">Forgot Password?</Link>
                      <span> | </span>
                      <Link to={"/signup"} className="create-account">Create a New Account</Link>
                    </div>
                    <div className="welcome-text">
                      <p>Glad to see you here again!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>

        <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-container">
        <input style={{color:"#000"}}  onChange={e => setResetPass(e.target.value)} type="email" placeholder="Enter your email" required />
        </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="login-btn" onClick={resetPassword}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
      </section>
    </Helmet>
  )
}

export default Login;