import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axiosClient from '../axios-client'
import { Config } from '../Config';
import './PortfolioEvent.css'
import { FaEye } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Spinner from '../components/Spninnerloader/Spinner';

function PortfolioEvent(props) {
    
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(true);

    // console.log("selectedImage", selectedImage)

    const { id } = useParams();
    const [eventData, setEventData] = useState([]);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                setLoading(true)
                const response = await axiosClient.get(`/event/get/${id}`);
                setEventData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventData();
    }, [id]);

    const eventProduct = eventData.event_product
        ? eventData.event_product.flatMap(product => product.product_details)
        : [];


        const handleImageClick = (imgUrl) => {
            setSelectedImage(imgUrl);
        };
        const getFullImageUrl = (imagePath) => {
            return `${Config.baseUrl}/${imagePath}`;
        };
    return (
        <div className='container'>
            {loading ? (<Spinner/>) : (
            <div className='row event_main'>
                <div className='col-md-6 event_img'>
                    <img 
                        loading='lazy'
                        src={selectedImage ? getFullImageUrl(selectedImage) : getFullImageUrl(eventData.event_main_image)} 
                        alt={selectedImage ? 'Selected Event Image' : 'HCL Conference'} />
                </div>
                <div className='col-md-6'>
                    <h1 className='event_heading'>{eventData.event_name}</h1>
                    <div>
                        <div className='event_date'>{eventData.event_date}</div>
                        <div>Event Cost Price: <strong>${eventData.event_sell_price}</strong></div>
                        <div className='event_retail_price'>Cost of Equipment Rental: <span> ${eventData.cost_equip_rental}</span></div>
                        <div className='event_labor_price'>Cost of Labor: <span>${eventData.labour_cost} </span></div>
                    </div>
                    <div className='hgbrvfdcs'>
                        {eventData.img && eventData.img.map((imgData, index) => (
                            <div 
                                className='event_img_list' 
                                key={index}    
                                onClick={() => handleImageClick(imgData.event_img)}
                            >
                                <img loading='lazy' className='productImage' src={getFullImageUrl(imgData.event_img)} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='event_des'>
                    <div className='event_des_heading'>
                        <h2>{eventData.event_heading}</h2>
                    </div>
                    <p className='event_des_para'>
                        {eventData.event_description}
                    </p>
                    <div>
                        <div className='event_des_team'>
                            <span>Technicians Who Worked on the Project:</span>
                        </div>
                        <div className='event_team'>
                            {eventData.event_tech && eventData.event_tech.map((imgData, index) => (
                                <img loading='lazy' key={index} className='te' src={Config.baseUrl + "/" + imgData.tech_details.image} alt='' />
                            ))}
                        </div>
                    </div>
                    <div className='product_item'>
                        <div className='event_item'>
                            <div className='item_heading'>AV EQUIPMENT CLIENT ORDERED</div>
                        </div>
                        {eventProduct && eventProduct.map((imgData, index) => (
                            <div key={index} className='row event_product'>
                                <div className='col-md-8'>
                                    <div className='event_pro'>
                                        <img loading='lazy' className='event_pro_img' src={Config.baseUrl + "/" + imgData.image_path} alt="" />
                                        <div className='event_pro_info'>
                                            <Link to={`/shop/${imgData.id}`}>
                                                <h3 className='event_pro_heading'>{imgData.name}</h3>
                                            </Link>
                                            <p className='event_pro_para'>Take your event to the next level with the High Resolution LED Video Wall Bundle:</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 event_pro_price'>
                                    <div>
                                        <div className='rental_price'>
                                            Rental Price: 
                                            <span>${imgData.retail_price}</span>
                                        </div>
                                        <div className='delivery_info'>
                                            <FaRegQuestionCircle style={{ marginRight: '8px' }} />
                                            Estimated Delivery and Installation: 
                                            <span>${imgData.delivery_cost}</span>
                                        </div>
                                        <div className='event_btn'>
                                            <Link to={`/shop/${imgData.id}`}>
                                                <button className='event_btn_devilery'>
                                                    <FaEye style={{ marginRight: '8px' }} />
                                                    View More
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}

export default PortfolioEvent
