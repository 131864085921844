import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import axiosClient from '../axios-client'

const Faq = () => {

    const [faq, setFaq] = useState();
    const [faqs, setFaqs] = useState("");

    useEffect(()=>{
        axiosClient.get("faq/list").then((data)=>{
            setFaq(data.data);;
        });
    },[]);

    useEffect(() => {
        axiosClient.get('seo/faqs').then(({ data }) => {
          setFaqs(data.data);
        });
    
      }, [])
  return (
    <Helmet title={faqs && faqs.seo_title}>
        <meta name="description" content={faqs && faqs.seo_meta_desc} />
        <meta name="keywords" content={faqs && faqs.seo_meta_name} />
    <CommonSection title={faqs && faqs.seo_title}/>
        <Container>
            <Row className='py-md-5 d-flex  justify-content-center'>
                <div className='col-md-8'>
                    <div className="accordion" id="accordionExample">
                    {Array.isArray(faq) && faq.map((datalist, index) => {
                    return (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" >
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                    {datalist.title}
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {datalist.details}
                                </div>
                            </div>
                        </div>
                    );
                    })}
                    </div>
                </div>
            </Row>
        </Container>
    </Helmet>
  )
}

export default Faq;