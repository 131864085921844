import { CloseButton, Modal } from "react-bootstrap";
import TechSteps from "../pages/TechSteps";

export default function PlanModal({show, setShow, handleClose}) {
  return (
    <Modal
      show={show}
      size="xl"
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      id="modal_body"
    >
      <CloseButton
        onClick={handleClose}
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          zIndex: 9999,
          color: (theme) => theme.palette.grey[500],
        }}
      />
      <Modal.Body>
        <TechSteps />
      </Modal.Body>
    </Modal>
  );
}
