// src/components/ProductionSteps.js
import React, { useEffect, useState } from "react";
import "./production.css";
import Tech from "../assets/images/tech0.png";
import Tech1 from "../assets/images/tech1.png";
import Tech2 from "../assets/images/tech2.png";
import Tech3 from "../assets/images/tech3.png";
import Tech4 from "../assets/images/tech4.png";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AllProductList from "./AllproductList";
import { Link } from "react-router-dom";
import { cartActions } from "../redux/slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { userAction } from "../redux/slices/userSlice";

const TechSteps = () => {
  const gestuser = localStorage.getItem("GUEST_INFO") || localStorage.getItem("USER_DATA");

  const [getsUserInfo, setGestUserInfo] = useState(JSON.parse(gestuser));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectstate, setSelectstate] = useState()
console.log("gestuser",gestuser);
  const [formData, setFormData] = useState({
    name: getsUserInfo?.name || "",
    delivery: getsUserInfo?.delivery || "",
    pickup: getsUserInfo?.pickup || "",
    email: getsUserInfo?.email || "",
    phone: getsUserInfo?.phone || "",
    street: getsUserInfo?.street || "",
    city: getsUserInfo?.city || "",
    state: getsUserInfo?.state || "",
    zip_code: getsUserInfo?.zip_code || "",
  });


  const handleResetForm = () => {
    setFormData({
      name: "",
      delivery: "",
      pickup: "",
      email: "",
      phone: "",
      street: "",
      city: "",
      state: "",
      zip_code: "",
    });
  };

  const state = [
    {statename:"Alabama",zip:null},
    {statename:"Alaska",zip:null},
    {statename:"Arizona",zip:null},
    {statename:"Arkansas",zip:null},
    {statename:"California",zip:"36279"},
    {statename:"Colorado",zip:null},
    {statename:"Connecticut",zip:null},
    {statename:"Delaware",zip:null},
    {statename:"District Of Columbia",zip:null},
    {statename:"Florida",zip:"14256"},
    {statename:"Georgia",zip:null},
    {statename:"Hawaii",zip:null},
    {statename:"Idaho",zip:null},
    {statename:"Illinois",zip:null},
    {statename:"Indiana",zip:null},
    {statename:"Iowa",zip:null},
    {statename:"Kansas",zip:null},
    {statename:"Kentucky",zip:null},
]


  const [formID, setFormID] = useState();
  const [getzipcode, setGetzipcode] = useState('');

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  const handleRemoveAllItems = () => {
    dispatch(cartActions.removeCartAllItem());
  };

  console.log(formData, "formData");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    

    if (name === "state") {
      const selectedState = state.find((s) => s.statename === value);
      if (selectedState && selectedState.zip === null) {
        toast.error("Delivery is not available in this state.");
        return;
      }
    }


    if (name === "delivery") {
      const selectedDeliveryTime = new Date(value);
      const currentDateTime = new Date();

      if (selectedDeliveryTime < currentDateTime) {
        toast.error("Please select a delivery time after the current time.");
        return;
      }

      // Disable pickup time before the selected delivery time
      const pickupInput = document.querySelector('input[name="pickup"]');
      pickupInput.min = value;
    }

    if (name === "pickup") {
      const selectedPickupTime = new Date(value);
      const selectedDeliveryTime = new Date(formData.delivery);

      if (selectedPickupTime < selectedDeliveryTime) {
        toast.error("Please select a pickup time after the delivery time.");
        return;
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const gestuser = localStorage.getItem("GUEST_INFO");
  // const[getsUserInfo , setGestUserInfo] = useState(JSON.parse(gestuser));
  // console.log("getsUserInfo",getsUserInfo); 


  const Zipcode = async() =>{
   await axiosClient.get("zip_code/get").then((data)=>{
      setGetzipcode(data.data.data);
    })
  }

  useEffect(()=>{
    Zipcode();
  },[]);



  const postData = async (formData) => {
    handleRemoveAllItems();

    try {
      const response = await axiosClient.post("eventbuilder", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response:", response.data);
      setFormID(response.data.data);
      localStorage.setItem("form_builderId",JSON.stringify(response.data.data));
      if (response.data.message === "User Successfully created.") {
        toast.success(response.data.message);
        // navigate('/all/product');
      } else {
        toast.error("Failed to process your request. Please try again.");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("An error occurred while sending the data.");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const key in formData) {
      if (formData[key] === "") {
        toast.error("Please fill out all fields.");
        return;
      }
    }

    const currentDateTime = new Date();
    const selectedDeliveryTime = new Date(formData.delivery);

    if (selectedDeliveryTime < currentDateTime) {
      toast.error("Please select a delivery time after the current time.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const zipCodeRegex = /^\d{5}$/;
    if (!zipCodeRegex.test(formData.zip_code)) {
      toast.error("Please enter a 5 Digit zip code.");
      return;
    }

    if (
      cartItems &&
      cartItems.length > 0 &&
      window.confirm("Are you sure you want to remove all cart items?")
    ) {
      handleRemoveAllItems();
    }
    setIsSubmitting(true);

    await postData(formData);
    dispatch(userAction.setGuestInfo(formData));

    setTimeout(() => {
      window.location.href="/all/product";
    }, 1000);

    setIsSubmitting(false);
  };


  return (
    <div className="container glassy-effect">
      <h2 className="text-uppercase tech_h2">Event Builder</h2>
      <p className="modal_tech_p mb-3">
        Tell us the time and place of the event to start configuring your
        package.get a final price and book your production online in minutes.
      </p>
      <div className="row text-center mb-3 d-flex icons_tech">
        <div className="col">
          <img style={{ width: "70%" }} src={Tech} alt="Select Time" />
          <p>Select Time</p>
        </div>
        <div className="col">
          <i className="bi bi-arrow-right" style={{ fontSize: "2rem" }}></i>
        </div>
        <div className="col tech_after">
          <img style={{ width: "70%" }} src={Tech1} alt="Add Item" />
          <p>Add Item</p>
        </div>
        <div className="col">
          <i className="bi bi-arrow-right" style={{ fontSize: "2rem" }}></i>
        </div>
        <div className="col tech_after">
          <img style={{ width: "70%" }} src={Tech2} alt="Time And Labour" />
          <p>Time & Labour</p>
        </div>
        <div className="col">
          <i className="bi bi-arrow-right" style={{ fontSize: "2rem" }}></i>
        </div>
        <div className="col tech_after">
          <img style={{ width: "70%" }} src={Tech3} alt="Get Final Price" />
          <p>Final Price</p>
        </div>
        <div className="col">
          <i className="bi bi-arrow-right" style={{ fontSize: "2rem" }}></i>
        </div>
        <div className="col tech_after">
          <img style={{ width: "70%" }} src={Tech4} alt="Book Online" />
          <p>Book Online</p>
        </div>
      </div>
      <form >
        <div className="row mb-2 tech_row">
          <div className="col-12 mb-2">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control new_form_control"
            />
          </div>
          <div className="col">
            <label>Delivery Time</label>
            <input
              type="date"
              name="delivery"
              value={formData.delivery}
              onChange={handleChange}
              className="form-control new_form_control"
            />
          </div>
          <div className="col">
            <label>Pickup Time</label>
            <input
              type="date"
              name="pickup"
              value={formData.pickup}
              onChange={handleChange}
              className="form-control new_form_control"
            />
          </div>
        </div>
        <div className="row mb-2 tech_row">
          <div className="col">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control new_form_control"
            />
          </div>
          <div className="col">
            <label>Phone</label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="form-control new_form_control"
            />
          </div>
        </div>
        <div className="row mb-2 tech_row">
          <div className="col">
            <label>Street</label>
            <input
              type="text"
              name="street"
              value={formData.street}
              onChange={handleChange}
              className="form-control new_form_control"
            />
          </div>
          <div className="col">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="form-control new_form_control"
            />
          </div>
        </div>
        <div className="row mb-2 tech_row">
          <div className="col">
            <label>State</label>
                <select  className="form-control new_form_control"  value={formData.state} onChange={handleChange} name="state">
                {Array.isArray(state)&& state.map((state)=>{
                  return(<option key={state.statename} value={state.statename}selected={state.zip == "14256"} >{state.statename}</option>)
              })}
                </select>
          </div>
          <div className="col">
            <label>Zip Code</label>
            <select  className="form-control new_form_control" value={formData.zip_code} onChange={handleChange} name="zip_code">
              {Array.isArray(getzipcode)&& getzipcode.map((zip)=>{
            return(<option key={state.statename} value={zip.zip_code}  >{zip.zip_code}</option>)
              })}

  `         </select>
           
          </div>
        </div>
        <div className="button_form">
          {/* <Link to={`/all/product/`}> */}
          <button type="button" className="reset_formData" onClick={handleResetForm}>Reset</button>
          <button
            type="submit"
            className="btn btn-primary "
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
          {/* </Link> */}
        </div>
      </form>
      <div id="height_tech"></div>
    </div>
  );
};

export default TechSteps;
