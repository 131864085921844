import React, { useEffect, useState, useContext } from "react";
import "./SubCategory.css";
import { Config } from "../Config";
import { Link } from "react-router-dom";
import axiosClient from "../axios-client";
import { cartActions } from "../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencydata } from "../redux/slices/settingSlice";
import { PlanModalContext } from '../components/Layout/Layout';

function Subcategory({ category, subcategorydata }) {
  const [showAll, setShowAll] = useState(false);
  const url = window.location.href;
  const dispatch = useDispatch("");

  const [offerId, setofferId] = useState(0);
  const [color, setColor] = useState(false);
  const [size, setSize] = useState(false);
  const [productDiscount, setProductDiscount] = useState(0);
  const [productDetals, setProductDetails] = useState([]);
  const [productImg, setProductImages] = useState([]);
  const [productmainImg, setProductMainImages] = useState("");
  const [showImg, setshowImg] = useState("");
  const [qty, setQty] = useState(1);
  const currencySymbol = useSelector((state) => state.setting.currency);
  const basepath = useSelector((state) => state.setting.basepath);
  const guestInfo = useSelector((state) => state.user.guestInfo);
  const { show, setShow } = useContext(PlanModalContext);

  const productIds = subcategorydata.map((product) => product.id);

  useEffect(() => {
    setColor(false);
    setSize(false);
    dispatch(getCurrencydata());
    axiosClient.get(`product/details?id=${productIds}`).then(({ data }) => {
      data.color && setColor(data.color.split(","));
      data.size && setSize(data.size.split(","));
      setProductDetails(data);
      setProductMainImages(data.image_path);
      setshowImg(data.image_path);
      setProductImages(data.product_image);
      setProductDiscount(data.discount);
    });
  }, [url]);

  const addToCart = (item, type) => {
    dispatch(
      cartActions.addItem({
        id: item.id,
        productName: item.name,
        price: item.current_sale_price - productDiscount,
        imgUrl: item.image_path,
        offerId: offerId,
        color: color,
        size: size,
        qty: qty,
        delivery_cost : item.delivery_cost,
        installation_cost : item.installation_cost,
      })
    );

    type === "cartadd" && toast.success("Product added successfully");
  };

  const displayedData = showAll ? subcategorydata : subcategorydata.slice(0, 5);
  //console.log(displayedData, "displayedData")
  const truncateName = (name, maxLength = 13) => {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, maxLength) + "...";
  };

  const cateName = (name, maxLength = 45) => {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, maxLength) + "..."
  }

  return (
    <div className="container">
      {Array.isArray(subcategorydata) && subcategorydata.length > 0 ? (
        <>
          <div className="row card-item">
            {displayedData.map((subcategory, index) => (
              <div key={index} className="col-md-2 subCard">
                <div className="mainCard">
                  <img
                    loading="lazy"
                    src={Config.baseUrl + "/" + subcategory.image_path}
                    alt={subcategory.title}
                  />
                  {guestInfo !== null ? (
                    <button
                      onClick={() => addToCart(subcategory, "cartadd")}
                      className="card-btn"
                    >
                      <i
                        className="bi bi-cart3"
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#fff",
                          marginRight: "10px",
                        }}
                      ></i>
                      ADD TO PACKAGE
                    </button>
                  ) : (
                    <button
                      onClick={() => setShow(true)}
                      className="card-btn"
                    >
                      <i
                        className="bi bi-cart3"
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#fff",
                          marginRight: "10px",
                        }}
                      ></i>
                      ADD TO PACKAGE
                    </button>
                  )}
                  <div className="card-text">
                    <Link to={`shop/${subcategory.id}`}>
                      <h5 className="card-title">{truncateName(subcategory.name)}</h5>
                    </Link>
                    <p className="card-des">{cateName(subcategory.description)}</p>
                    <h5 className="card-title d-flex" style={{gap:"7px", display:"flex", alignItems:"center"}}>
                      <span style={{ fontSize: ".75rem", fontWeight: "700" }}>{currencySymbol} ${Number(subcategory.current_sale_price).toFixed(2)}</span>
                      <span style={{ display: "flex", alignItems: "center" }}><strike style={{ fontSize: ".65rem", fontWeight: "500" }}> {currencySymbol} ${Number(subcategory.retail_price).toFixed(2)} </strike>  </span>
                      <span style={{ fontSize: ".75rem", fontWeight: "700", color: "red" }}>{Number(subcategory.discount).toFixed(0)}% off</span>
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {!showAll && subcategorydata.length > 5 && (
            <div className="view-more-container">
              <Link to={`all/product?cat=${category.id}`} className="view-more-btn">
                View More
              </Link>
            </div>
          )}
        </>
      ) : (
        <div className="no-products">
          <p>No Products Available</p>
        </div>
      )}
    </div>
  );
}

export default Subcategory;
