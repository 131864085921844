import React from 'react'
import "./portfolio.css"
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axiosClient from '../axios-client'
import { Config } from '../Config';
import Spinner from '../components/Spninnerloader/Spinner';
import {
    Card,
    CardActions,
    CardContent,
    Skeleton,
    Typography,
  } from "@mui/material";
import { Helmet } from 'react-helmet'


const Portfolio = () => {

    const[eventData, setEventData] = useState([])
    const [loading, setLoading] = useState(true);

    const [portfolio, setPortfolio]= useState("");

    useEffect(() => {
      axiosClient.get('seo/portfolio').then(({ data }) => {
        setPortfolio(data.data);
      });
  
    }, [])


    console.log("eventData", eventData)

    useEffect(() => {
        const fetchData = async () => {
            try { 
            setLoading(true)
            const response = await axiosClient.get('event/get')
            setEventData(response.data.data)
            setLoading(false);
            } catch (error) {
                console.error('Error fetching slides:', error);
            }
        }
        fetchData()
    },[])


    const card = {
        width: "100%",
        // position:"relative"
      };
  return (
    <div className='container port_main'>
      <Helmet >
       <title>DJ Peoples - {portfolio && portfolio.seo_title}</title>
        <meta name="description" content={portfolio && portfolio.seo_meta_desc} />
        <meta name="keywords" content={portfolio && portfolio.seo_meta_name} />
       
    </Helmet>
        {loading ? 
        <div className='row'>
            {[1, 2, 3].map((index) => (
                    <div className="col-md-4 col-xs-6 col-lg-4" key={index}>
                      <Card style={card} className="image-containers mt-5 mb-3">
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={200}
                        />
                        <CardContent>
                          <Typography variant="h5" component="h4">
                            <Skeleton />
                          </Typography>
                          <Typography variant="body1">
                            <Skeleton width="100%" />
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Skeleton
                            variant="rectangular"
                            width="100px"
                            height="40px"
                          />
                          <Skeleton
                            variant="rectangular"
                            width="300px"
                            height="200px"
                            style={{ marginLeft: "auto" }}
                          />
                        </CardActions>
                      </Card>
                    </div>
                  ))}
        </div> : (
        <div className='row'>
        {Array.isArray(eventData) &&  eventData.map((item, index) => ( 
            <div  key={index} className='col-md-4 '>
                <div className='port_card '>
                <div className='port_img'>
                    <img loading='lazy' src={ Config.baseUrl + "/" + item.event_main_image} alt=''/>
                </div>
                <div className='port_item'>
                    <div>
                        <h2>{item.event_name}</h2>
                        <div className='efgetsg'>
                            <span>{item.event_date}</span>
                        </div>
                    </div>
                    <p className='port_para'>
                        {item.event_description}
                    </p>
                    <div className='port_event_cost'>
                        <h2>Event Cost Price: <strong>${item.event_sell_price}</strong></h2>
                    </div>
                    <div className='port_cost'>
                        Cost of Equipment Rental: ${item.cost_equip_rental}
                    </div>
                    <div>Cost of Labor: ${item.labour_cost}</div>
                    <div className='port_btn'>
                        <Link to={`/event/portfolio/${item.id}`}>
                            <button>See More</button>
                        </Link>
                        <div className='port_card_img'>
                            {Array.isArray(item.event_tech) && item.event_tech.map((imge)=>{
                                return(

                            <img className='liopliop' loading='lazy' src={Config.baseUrl + "/" + imge.tech_details.image} alt={imge.tech_details.name}/>
                        )
                            }) }
                        </div>
                    </div>
                </div>
                </div>
            </div>
            ))}
        </div>
        )}
    </div>
  )
}

export default Portfolio