import React, { useEffect, useState, useContext } from 'react'
import './PackageProduct.css'
import { BsTruck } from "react-icons/bs";
import axiosClient from '../axios-client';
import { Config } from '../Config';
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { PlanModalContext } from '../components/Layout/Layout'; 
import { moment } from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spninnerloader/Spinner';

function PackageProduct(props) {

    const{packegaId} = props;

    const [packageData, setPackageData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [products, setProducts] = useState([]);
    const [showProductList, setShowProductList] = useState(false);
    const dispatch = useDispatch("");
    const {show, setShow} = useContext(PlanModalContext);
    const [getIdform,setGetIdform] = useState();
      const guestInfo = useSelector((state) => state.user.guestInfo);
      const navigate = useNavigate()


    const getfomrData = async() =>{
        try {
          await axiosClient.get('eventbuilder/get/id').then(resopnse=>{
            setGetIdform(resopnse.data.data)
          });
        } catch (error) {
          console.log(error);
        }
      }
      useEffect(()=>{
        getfomrData();
      },[]);


    const handleAddToCart = () => {
        const updatedAt = moment(getIdform.updated_at);
        const fiveMinutesAgo = moment().subtract(5, 'minutes');
        
        if (updatedAt.isAfter(fiveMinutesAgo)) {
          addToCart(products, 'cartadd');
        } else {
          setShow(true); // This line opens the modal
        }
      };


    const packageContent = async () => {
        try {
            setLoading(true)
             await axiosClient.get(`/package/category/${packegaId}`).then(itemslist=>{
                 setPackageData(itemslist.data.data);
                 setLoading(false);
             });
        } catch (error) {
           console.log(error); 
        }
    }

   

    useEffect(() => {
        packageContent();
    }, [packegaId])


    const handleViewProduct = (packageId, morepack) => {
        setSelectedPackage(packageId);
        const productNames = morepack.map(item => item.productname);
        setProducts(productNames);
        setShowProductList(true);
        navigate(`/packagelist/${packageId}`) 
        //console.log("packageId",packageId)// Toggle the showProductList state
    };

    //  console.log("packageData",packageData);


    const truncateName = (name) => {
        const words = name.split(" ");
        if (words.length <= 5) {
            return name;
        }
        return words.slice(0, 5).join(" ") + "...";
    };

    const addToCart = (items,quantity, type) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            if (item && item.productname) {
            dispatch(
              cartActions.addItem({
                id: item.productname.id,
                productName: item.productname.name,
                price: item.productname.current_sale_price,
                imgUrl: item.productname.image_path,
                offerId: item.productname.offerId,
                color: item.productname.color,
                size: item.productname.size,
                qty: quantity,
              })
            );
            }
          });
        } else {
           
          dispatch(
            cartActions.addItem({
              id: items.productname.id,
              productName: items.productname.name,
              price: items.productname.current_sale_price,
              imgUrl: items.productname.image_path,
              offerId: items.productname.offerId,
              color: items.productname.color,
              size: items.productname.size,
              // qty: items.unit,
            })
          );
        }
      
        type === "cartadd" && toast.success("Product added successfully");
      };
      

    //   console.log("addToCart", addToCart)
    if (loading) {
        return <div><Spinner/></div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (packageData.length === 0) {
        return <div className='no-product'>No Product Available</div>;
    }

    console.log("packageData",packageData);
      
  return (
    <div className='container'>
        <div className='row'>
            {Array.isArray(packageData) && packageData.map((item, index) =>{ 
                return (
            <div key={index} className='col-md-4'>
                <div>
                    <div className='pack-product'>
                        <h2 className='pack-pro-heading'>
                           {item.pack_name}
                        </h2>
                        {selectedPackage === item.id ? (
                            <div className='pack-product-list'>
                                <ul>
                                <div className='container porductlist'>
                                   {Array.isArray(item.morepack) && item.morepack.length > 0 ? (
                                        item.morepack.map((list, index) => (
                                            list.productname ? (
                                            <li key={index} className="pack-product-item">
                                                <div className='pack-product_info'>
                                                <img
                                                    loading='lazy'
                                                    src={Config.baseUrl + "/" + list.productname.image_path}
                                                    alt={list.productname.name}
                                                    className="product-image"
                                                    style={{ height: "50px", width: "50px" }}
                                                />
                                                <p className="pack-product-name">{truncateName(list.productname.name)}</p>
                                                </div>
                                                <p className="product-quantity">{list.unit} Units</p>
                                            </li>
                                            ) : null // Replace the removed line with null
                                        ))
                                        ) : (
                                        <li className='no_product'>No product available</li>
                                        )}
                                </div>
                                </ul>
                            </div>
                            ) : (
                            <img
                                loading='lazy'
                                className='pack-pro-img'
                                src={Config.baseUrl + "/" + item.pack_img}
                                alt=""
                            />
                            )}
                            <p className='package-pro-desc'>{item.pack_desc}</p>
                            <div className='pack-pro-price'>
                                <div>
                                    <span className='pack-price'>
                                    ${item.pack_actual_cost}
                                    </span>
                                </div>
                                <div>
                                    <span className='pack-price'>
                                        ${item.pack_price}
                                    </span>
                                </div>
                            </div>
                            <div className='fgjchwewe'>
                              <Link to={`/packagelist/${item.id}`}>
                                    <button 
                                        className='hrntbgrf'
                                        // onClick={() => handleViewProduct(item.id, item.morepack)}
                                    >
                                        View Package
                                    </button>
                                    </Link>
                                    <div id="ho-button">
                                    {guestInfo !== null ?(
                                    <button className='brgvfcsx'  onClick={() => item.morepack && addToCart( item.morepack,item.unit, "cartadd")}
                                          >
                                        <BsTruck style={{ marginRight: '8px' }} />
                                        Book Package
                                    </button>
                                    ): ( <button className='brgvfcsx' onClick={()=>{setShow(true)}}
                                          id="ho-button">
                                        <BsTruck style={{ marginRight: '8px' }} />
                                        Book Package
                                    </button>
                                          )}
                                    </div>    
                            </div>
                        </div>
                    </div>
            </div>
            )})}
        </div>
    </div>
  )
}

export default PackageProduct;