import React, { useEffect, useState } from 'react';
import "./main.css";
import CostBreakdown from './CostBreakdown.jsx';
import FullInfo from './FullInfo';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import axiosClient from '../axios-client.js';
import { getCurrencydata } from '../redux/slices/settingSlice.js';

function Review() {

    const dispatch = useDispatch();
    const totalQty = useSelector((state) => state.cart.totalQuantity);
    const subtotal = useSelector((state) => state.cart.totalAmount);
    const shippingCostget = useSelector((state) => state.setting.shippingCost);
    const shippingCostdata = subtotal > 0 ? shippingCostget : 0;
    const totalAmount = parseFloat(subtotal) + parseFloat(shippingCostdata);
    const cartItems = useSelector((state) => state.cart.cartItems);
    const currency = useSelector((state) => state.setting.currency);
    const [shippingInfo, setShippingInfo] = useState([]);
    const basepath = useSelector((state) => state.setting.basepath);
    let [relatedProducts, setRelatedProducts] = useState([]);





    const [activeItem, setActiveItem] = useState(0);
    const navigate = useNavigate();
    const shippingAddress = (address) => {
        setShippingInfo(address)
        setActiveItem(2);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCurrencydata())
    }, [])

    useEffect(() => {
        dispatch(getCurrencydata());

        var pid = [];
        cartItems.map(data => {
            pid = [...pid, data.id];
        });

        axiosClient
            .get(`home/subcategory/product/related?productlist=${[pid]}`)
            .then(({ data }) => {
                setRelatedProducts(data)
                // console.log('data6', data)
            });

    }, []);


    const orderStepAction = (data) => {
        setActiveItem(data)
    }

    useEffect(() => {

    }, [activeItem])

    return (
        <>
            <div className="thubnail_setting">
                <h1>Review Package</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Review Package</li>
                    </ol>
                </nav>
            </div>
            <div className="container-fluid">
                <div className="row new_row" style={{ position: "relative" }}>
                    <div className="col-8">
                        <FullInfo
                            cartItems={cartItems}
                            basepath={basepath}
                        />
                    </div>
                    <div className="col-4 review_col_4">
                        <CostBreakdown
                            totalAmount={totalAmount}
                            
                            
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Review;