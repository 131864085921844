import React, { useEffect, useState, useMemo } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";

import {
  AiOutlineHeart,
  AiOutlineShopping,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getWishcount } from "../../redux/slices/settingSlice";
import { toast } from "react-toastify";
import axiosClient from "../../axios-client";
import Spinner from "../../components/Spninnerloader/Spinner";
import Avatar from '../../../src/assets/images/avatar.png'
const UserProfile = (props) => {
  const dispatch=useDispatch();
  const wishcount = useSelector((state) => state.setting.wishcount);
  const totalcart = useSelector((state) => state.cart.totalQuantity);
  useEffect(()=>{
    dispatch(getWishcount());
  },[]);
  const orderlistshow=()=>{
      props.orderlist();
  }

  const gestuser = localStorage.getItem("GUEST_INFO") || localStorage.getItem("USER_DATA");
  const userDetails = localStorage.getItem("userDetails") || localStorage.getItem("USER_DATA");
  const [getsUserInfo, setGestUserInfo] = useState(JSON.parse(userDetails));
  const basepath = useSelector((state) => state.setting.basepath);
   //console.log("getsUserInfo", getsUserInfo)
   const [selectedImage, setSelectedImage] = useState(null);
   const [userphoto, setUserphoto] = useState('')
   const [userInfo, setUserInfo]= useState('');

   const [updatting, setUpdateting] = useState(false)
   const [waiting, setWaiting] = useState(false)
   const [updateuser, setUpdateuser] = useState({
    name: getsUserInfo?.name || "",
    email:getsUserInfo?.email || "",
    phone: getsUserInfo?.phone ||  "",
    photo:getsUserInfo?.photo|| "",
   });



   
   const getuserDetails = async() =>{
    setWaiting(true)
     await axiosClient.get("/user/details").then((data)=>{
      setUserInfo(data.data);
      localStorage.setItem("userDetails",JSON.stringify(data.data));
      setSelectedImage(`${basepath}/${data.data.photo}`);
     })
     setWaiting(false);
   }

   useEffect(()=>{
    if (!userInfo) {  // Only fetch if userInfo is not available
      getuserDetails();
    }
   },[]);
   const handleImageClick = () => {
     document.getElementById('imageInput').click();
  };

   const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setUserphoto(file);
    }
  };  
   const handlechange = (e) =>{
    const {name, value} = e.target;
    setUpdateuser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
   }

   const handleUpdaet = async() =>{
    setUpdateting(true)
     const formData = new FormData();
     if (userphoto) {
      formData.append('photo', userphoto); // Append the new image file if the user selected a new one
    } else {
      formData.append('photo', updateuser.photo); // Append the existing image URL if no new image was selected
    }
    formData.append('name', updateuser.name);
    formData.append('email', updateuser.email);
    formData.append('phone', updateuser.phone);
   try{
    await axiosClient.post("/user/update/details",formData,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    toast.success('User Profile Successfully Updated')
    
  }catch(error){
      console.log(error);
    }
    getuserDetails();
    setUpdateting(false);
   }


   const memoizedImage = useMemo(() => {
    return selectedImage|| Avatar || `${basepath}/${updateuser.photo}` ;
  }, [selectedImage, userphoto, updateuser.photo, basepath]);
  return (
    <Helmet title="Login">
      <section>
        <Container>
          { waiting ? <Spinner/>:<Row className="dashboardCard">
            <Col lg="6" className="profileCardLeft">
            <div className="roundImg">
            <img
              src={memoizedImage}
              alt="Profile"
              onClick={handleImageClick}
              style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickability
            />
            <input
              type="file"
              id="imageInput"
              style={{ display: 'none' }}
              accept="photo/*"
              onChange={handleImageChange}
            />
          </div>
              <div>
                <span className="userName">Welcome</span>
                <br />
                <span>{userInfo && userInfo.name}</span>
              </div>
            </Col>
            <Col lg="6" className="profileCardRigt">
              <Button variant="contained" className="logoutbtnst">
                Logout
              </Button>
            </Col>
            <Col lg="12" className="userProfile__info_data">
            <form className="user_updateform">
              <div className="d-flex justify-contanet-space">
              <input name="name" value={updateuser.name}  onChange ={handlechange}className="update_form" type="text"  placeholder="name"/>
              <input name="email" value={updateuser.email} onChange={handlechange} className="update_form" type="email" placeholder="Email"/>
              </div>
              <input name="phone" value={updateuser.phone} onChange={handlechange} className="update_form" type="number" placeholder="Phone"/>
            <button className="btn_dev" type="button" disabled={updatting} onClick={handleUpdaet}>{updatting? "updating":"Update"}</button>
            </form>
              {/* <div className="profile__main-info-item" onClick={orderlistshow}>
                <span className="roundicon">0</span>
                <AiOutlineCheckCircle className="itemi__con" />
              
                <span className="iconname">Order Done</span>
              </div> */}
              <Link to={"/product/wishlist"}>
              {/* <div className="profile__main-info-item">
                <span className="roundicon">{wishcount}</span>
                <AiOutlineHeart className="itemi__con" />
                <span className="iconname"> Wishlist</span>
                
              </div> */}
              </Link>
              <Link to={"/checkout"}>
              {/* <div className="profile__main-info-item">
         
              <span className="roundicon">{totalcart}</span>
                <AiOutlineShopping className="itemi__con" />
                <span className="iconname">Cart</span>
             
              </div> */}
              </Link>
            </Col>
          </Row>}
        </Container>
      </section>
    </Helmet>
  );
};

export default UserProfile;
