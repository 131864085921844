import React from 'react'
import { useState } from 'react'
import { Table,thead,Row,Col} from 'reactstrap'
import axiosClient from '../../axios-client';
import { useEffect } from 'react';
import './invoice.css'
import moment from 'moment';
import { useSelector } from 'react-redux';

const Invoice = (props) => {
  const {order_id} = props;
  console.log("order_id",order_id)

    let [orderInfo,setOrderInfo]=useState([]);
    const [orderAddreess, setorderAddress] = useState([]);
    const [invoiceId, setIncoiceId] = useState("");
    const [invoiceDate, setIncoiceDate] = useState("");
    const [sellDetails, setSellDetails] = useState([]);
    useEffect(() => {

        if(order_id>0){
            axiosClient.get(`user/order/details/by/orderId?id=${props.order_id}`).then(({ data }) => {

            
                setorderAddress(data.data[0].orderAddress);
                setIncoiceId(data.data[0].invoice_id)
                setIncoiceDate(data.data[0].date_format)
                setSellDetails(data.data[0].sellDetails)
                setOrderInfo(data.data[0])
                console.log(data.data[0]);
                // shipping_address
            
                // setOrderInfo(data)
            });
        }
       
    }, [])
  return (
    <div className='w-100 set-invpd'>
       
  <Row>
    <Col className=""xs="6">
     <ul style={{textAlign: "left",paddingLeft: "0px"}}>
        <li>
        <h6> <strong>Shipping Address</strong> </h6>
        </li>
     </ul>
    </Col>
    <Col
      className=""
      xs="6"
    >
       <ul style={{textAlign: "right"}}>
        {/* <li>
        <h6>Shipping Address</h6>
        </li> */}
        <li>
            <strong>Invoice: {order_id && order_id.invoice_id}</strong>
        </li>
        
        {/* <li>
            <strong>Phone:</strong> <span>01792323423</span>
        </li>
        <li>
            <strong>Email:</strong> <span>msdf@gamil.com</span>
        </li> */}
     
     </ul>
    </Col>
    <Col className=" mt-2" xs="12">
    <ul className='address_shipping'>
        <li>
        <strong>Name:</strong> <p className="address_text">{order_id.address && order_id.address.name}</p>
        </li>
        <li>
            <strong>Phone:</strong> <p className="address_text" >{order_id.address && order_id.address.phone}</p>
        </li>
        <li>
            <strong>Email:</strong> <p className="address_text">{order_id.address && order_id.address.email_id}</p>
        </li>
        <li>
            <strong>Date:</strong> <p className='address_text'> {moment(order_id && order_id.created_at).format("DD/MM/YYYY")}</p>
        </li>
        <li>
            <strong>Order type:</strong> <p className='address_text'>{order_id && order_id.paymt_type}</p>
        </li>
        </ul>
    </Col>
 
  </Row>

<Table className='tablesst mt-4' >
  <thead >
    <tr className='tbcolorst'>
      <th>SI</th>
      <th>Image</th>
      <th>Name</th>
      <th>QTY</th>
      <th>UNIT PRICE</th>
      <th>AMOUNT</th>
    </tr>
  </thead>
  <tbody>
    {
        Array.isArray(order_id.product) && order_id.product.map((data,index)=>{
            return <InvoiceDetails data={data} si={index+1}/>
        })
    }



    <tr>
      <th colSpan={3}>
      </th>
      <td className='textright_st'>
      <strong>Subtotal:</strong>
      </td>
      <td className='flotlert-set'>
        ${order_id && order_id.products_cost}
        {/* { Math.round(orderInfo.total_payable_amount) } */}
      </td>
    </tr>

    <tr>
      <th colSpan={3}>
      </th>
      {/* <td className='textright_st'>
      <strong>Vat :</strong>
       
      </td>
      <td className='flotlert-set'>
        0
      </td> */}
    </tr>
    <tr>
      <th colSpan={3}>
      </th>
      <td className='textright_st'>
      <strong>Shipping :</strong>
      </td>
      <td className='flotlert-set'>
        ${order_id && order_id.sippment_cost}
      </td>
    </tr>
    <tr className='borderTopsst'>
      <th colSpan={3}>
      </th>
      <td className='textright_st'>
        <strong>Total :</strong>
       
      </td>
      <td className='flotlert-set'>
        <strong>
          ${order_id && order_id.total_cost}
           {/* { Math.round(orderInfo.total_payable_amount) } */}
           </strong>
     
      </td>
    </tr>

  </tbody>
</Table>
    </div>
  )
}

const InvoiceDetails=(props)=>{
  const basepath = useSelector((state) => state.setting.basepath);

    return  ( <tr>
    <th scope="row">
      {props.si}
    </th>
    <th scope="row">
      <img  style={{width:"10%"}} src={`${basepath}/${ props.data.product_details && props.data.product_details.image_path}`}/> 
    </th>
    <td >
      {props.data.product_details.name}
    </td>
    <td >
    {props.data.unit}
    </td>
    <td >
      ${props.data.single_price}
    {/* { Math.round(props.data.single_price-props.data.total_price) } */}
    </td>
    <td  className='flotlert-set'>
      ${props.data.total_price}
    </td>
  </tr>)

}

export default Invoice