import React, { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row, Input } from "reactstrap";
import { useLocation } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { shippingCost,getCurrencydata } from "../../redux/slices/settingSlice";
import axiosClient from "../../axios-client";
import DatePicker from "react-datepicker";

const OrderShippingAddress = (props) => {
  const gestuser = localStorage.getItem("GUEST_INFO");
  const [getsUserInfo, setGestUserInfo] = useState(JSON.parse(gestuser));


  // console.log("getsUserInfo", getsUserInfo)
    const [emailShippingRef, setemailShippingRef] = useState('');
    const [first_nameShippingRef, setfirst_nameShippingRef] = useState('');
    const [last_nameShippingRef, setlast_nameShippingRef] = useState('');
    const [addressShippingRef, setaddressShippingRef] = useState('');
    const [countryShippingRef, setcountryShippingRef] = useState('');
    const [cityShippingRef, setcityShippingRef] = useState('');
    const [stateShippingRef, setstateShippingRef] = useState('');
    const [zipShippingRef, setzipShippingRef] = useState('');
    const [addressBillingRef, setaddressBillingRef] = useState('');
    const [countryBillingRef, setcountryBillingRef] = useState('');
    const [cityBillingRef, setcityBillingRef] = useState('');
    const [stateBillingRef, setstateBillingRef] = useState('');
    const [zipBillingRef, setzipBillingRef] = useState('');
    const [shippingPhone, setshippingPhone] = useState('');
  
    const [billingPhone, setbillingPhone] = useState('');
    const [firstnameBillingRef, setfirstnameBillingRef] = useState('');
    const [lastnameBillingRef, setlastnameBillingRef] = useState('');
    const [emailBillingingRef, setemailBillingRef] = useState('');
  
    const [countryList, setCountryList] = useState([]);
    const [userAddressData, setUserAddressData] = useState([]);
  
    const [districtList, setdistrictList] = useState([]);
    const [divisionShippingRef, setDivisionShippingRef] = useState('india');
    const [divisionList, setdivisionList] = useState([]);
  
    const [districtShippingRef, setDistrictShippingRef] = useState('india');

    const [installationDate, setInstallationDate] = useState(new Date());
    const [installationTimeFrom, setInstallationTimeFrom] = useState(new Date());
    const [installationTimeTo, setInstallationTimeTo] = useState(new Date());

    const [breakdownDate, setBreakdownDate] = useState(new Date());
    const [breakdownTimeFrom, setBreakdownTimeFrom] = useState(new Date());
    const [breakdownTimeTo, setBreakdownTimeTo] = useState(new Date());
  
    const dispatch = useDispatch();
  
  
  
    const userInfodata = {
      name: getsUserInfo ? getsUserInfo.name || "" : "",
      email: getsUserInfo ? getsUserInfo.email ||"":"",
      phone: getsUserInfo ? getsUserInfo.phone ||"":"",
      address: getsUserInfo ? getsUserInfo.address ||"":"",
      state: getsUserInfo ? getsUserInfo.state ||"":"",
      city: getsUserInfo ? getsUserInfo.city ||"":"",
      zip: getsUserInfo ? getsUserInfo.zip_code ||"":"",
      street: getsUserInfo ? getsUserInfo.street ||"":"",
      landmarks: "",
      delivery: getsUserInfo ? getsUserInfo.delivery || "":"",
      pickup: getsUserInfo ? getsUserInfo.pickup || "":"",
    };
  
    const [userInfo, setUserInfo] = useState(userInfodata);

    console.log(userInfo, "userInfo")
  
  
    useEffect(() => {
      axiosClient.get("division/list").then(({ data }) => {
        setdivisionList(data)
      });
  
      axiosClient.get("country/list").then(({ data }) => {
        setCountryList(data)
      });
      axiosClient.get("user/shipping/billing/address/get").then(({ data }) => {


        if(data.data){
        setUserAddressData(data.data);
        setemailShippingRef(data.data.email);
        setfirst_nameShippingRef(data.data.first_name);
        setlast_nameShippingRef(data.data.last_name);
        setaddressShippingRef(data.data.shipping_address);
        setcountryShippingRef(data.data.shipping_country);
        setcityShippingRef(data.data.shipping_city);
        setstateShippingRef(data.data.shipping_state);
        setzipShippingRef(data.data.shipping_zip);
        setaddressBillingRef(data.data.billing_address);
        setcountryBillingRef(data.data.billing_country);
        setcityBillingRef(data.data.billing_city);
        setstateBillingRef(data.data.billing_state);
        setzipBillingRef(data.data.billing_zip);
        setshippingPhone(data.data.shipping_phone);
        setbillingPhone(data.data.billing_phone);
        setfirstnameBillingRef(data.data.billing_first_name);
        setlastnameBillingRef(data.data.billing_last_name);
        setemailBillingRef(data.data.billing_email);
        // if (data.data.shipping_division) getDistrictList(data.data.shipping_division)
        // if (data.data.shipping_district) setDistrictShippingRef(data.data.shipping_district)
  
        setUserInfo({
          name: data.data.name || data.data.first_name,
          email: data.data.email,
          phone: data.data.shipping_phone,
          address: data.data.shipping_address,
          state: data.data.shipping_state,
          city: data.data.shipping_city,
          zip: data.data.shipping_zip,
          street: data.data.shipping_street,
          landmarks: data.data.shipping_landmarks
          // country: `${data.data.billing_country}`,
          // division: `${data.data.shipping_division == null ? 0 : data.data.shipping_division}`,
          // district: `${data.data.shipping_district == null ? 0 : data.data.shipping_district}`,
        })

        dispatch(shippingCost({ divisionId: userInfo.division, districtId: setUserInfo.district}))
      }
      });
    }, [])
  
    let getDistrictList = (divisionId) => {
      axiosClient.get(`district/list?divisionId=${divisionId}`).then(({ data }) => {
        setdistrictList(data)
      });
      setDivisionShippingRef(divisionId)
    }
  
    let onchangeDivision = (event) => {
      getDistrictList(event.target.value)
      inputdata(event)
    }
    let onChangeDistrict = (event) => {
      setDistrictShippingRef(event.target.value);
      inputdata(event);
      dispatch(shippingCost({ divisionId: userInfo.division, districtId: event.target.value }))
  
    }
  
    const [infoRequired, setinfoRequired] = useState(userInfodata);
    const inputdata = (e) => {
      let name = e.target.name;
      let dataValue = e.target.value;
      setUserInfo((prev) => ({ ...prev, [name]: dataValue }));
    };


    
    const placeOrder = () => {
      setinfoRequired(userInfodata);
      var isBreak = 0;
      const petList = Object.entries(userInfo).map(([key, value]) => {
        if (!value || value == 0) {
          value = "empty";
          isBreak = 1;
        }
        setinfoRequired((prev) => ({ ...prev, [key]: value }));
      });
      if (isBreak) {
        return false;
      }
  
  
      props.address(userInfo);
  
    };

    
  
    return <>
  
      <h6 class="mb-1 pb-3 checkout__txt "> Shipping Address </h6>
  
      <Form className="billing__form userInfoUse">
        <FormGroup className="form__group inputbasehulf mb-0">
          <h6 className="infoname__st">Name </h6>
          <Input
            type="text"
            name="name"
            value={userInfo.name}
            placeholder="Enter Your Name"
            onChange={inputdata}
          />
          {infoRequired.name == "empty" && (
            <span className="error__tx">Name is Required</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className=" infoname__st">Email</h6>
          <Input
            value={userInfo.email}
            type="email"
            name="email"
            placeholder="Enter Your Email"
            onChange={inputdata}
          />
          {infoRequired.email == "empty" && (
            <span className="error__tx">Email is Required</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">Phone Number </h6>
          <Input
            value={userInfo.phone}
            type="number"
            name="phone"
            placeholder="Enter your Number"
            onChange={inputdata}
          />
          {infoRequired.phone == "empty" && (
            <span className="error__tx">Phone is Required</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">Address </h6>
          <Input
            value={userInfo.address}
            type="text"
            name="address"
            placeholder="Street address"
            onChange={inputdata}
          />
          {infoRequired.address == "empty" && (
            <span className="error__tx">Address is Required</span>
          )}
        </FormGroup>
          
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">City </h6>
  
  
          <Input type="text" name="city" id="exampleSelect" value={userInfo.city} onChange={onChangeDistrict} placeholder="City">
            {/* {
              Object.keys(countryList).map((data, index) => {
                return <option value={countryList[data].name}>{countryList[data].name}</option>
              })
            } */}
            {/* <option value="MIAMI">MIAMI</option>
            <option value="NEW YORK">NEW YORK</option>
            <option value="CALIFORINA">CALIFORINA</option>
            <option value="WASHINGTON">WASHINGTON</option> */}
          </Input>
  
          {infoRequired.city == "empty" && (
            <span className="error__tx">City is Required</span>
          )}
        </FormGroup>


        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">Street</h6>
          <Input
            type="text"
            name="street"
            placeholder="Street"
            value={userInfo.street}
            onChange={inputdata}
          />

          {infoRequired.street == "empty" && (
            <span className="error__tx">Street is Required</span>
          )}
        </FormGroup>

        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">State </h6>
  
  
          <Input type="text" name="state" id="exampleSelect" value={userInfo.state} onChange={inputdata} placeholder="state">
            {/* {
              Object.keys(countryList).map((data, index) => {
                return <option value={countryList[data].name}>{countryList[data].name}</option>
              })
            } */}
            
          </Input>
  
          {infoRequired.state == "empty" && (
            <span className="error__tx">State is Required</span>
          )}
        </FormGroup>
  
        {/* <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">Country </h6>
  
  
          <Input type="select" name="country" id="exampleSelect" value={countryList} onChange={inputdata} placeholder="Country">
            {
              Object.keys(countryList).map((data, index) => {
                return <option value={countryList[data].name}>{countryList[data].name}</option>
              })
            }
            <option value="INDIA">INDIA</option>
            <option value="AMERICA">AMERICA</option>
            <option value="RUSSIA">RUSSIA</option>
            <option value="WEST INDIES">WEST INDIES</option>
          </Input>
  
          {infoRequired.country == "empty" && (
            <span className="error__tx">Country is Required</span>
          )}
        </FormGroup> */}
        {/* <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">Division </h6>
  
  
          <Input type="select" name="division" id="exampleSelect" value={divisionShippingRef} onChange={onchangeDivision} placeholder="Division">
            <option value="NORTH">NORTH DIVISION</option>
            <option value="SOUTH">SOUTH DIVISION</option>
            <option value="EAST">EAST DIVISION</option>
            <option value="WEST">WEST DIVISION</option>
            {
              divisionList.map((data, index) => {
                return <option value={data.id}>{data.name}</option>
              })
            }
  
          </Input>
  
  
          {infoRequired.division == "empty" && (
            <span className="error__tx">Division is Required</span>
          )}
        </FormGroup> */}
        {/* <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">District </h6>
  
  
  
          <Input type="select" name="district" id="exampleSelect" value={districtShippingRef} onChange={onChangeDistrict} placeholder="Country">
            <option value="0">SELECT DISTRICT</option>
            {
              districtList.map((data, index) => {
                return <option value="miami"></option>
                return <option value=""></option>
                return <option value="miami"></option>
              })
            }
              <option value="MIAMI">MIAMI</option>
              <option value="NEWYORK">NEW YORK</option>
              <option value="WASHINGTON">WASHINGTON</option>
          </Input>
  
  
  
          {infoRequired.district == "empty" && (
            <span className="error__tx">District is Required</span>
          )}
        </FormGroup> */}
  
        <FormGroup className=" form__group inputbasehulf">
          <h6 className="infoname__st">Postal Code </h6>
          <Input
            type="text"
            value={userInfo.zip}
            name="zip"
            placeholder="zip"
            onChange={inputdata}
          />
          {infoRequired.zip == "empty" && (
            <span className="error__tx">Postal Code is Required</span>
          )}
        </FormGroup>

        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">Landmarks</h6>
          <Input
            type="text"
            name="landmarks"
            placeholder="Landmarks"
            value={userInfo.landmarks}
            onChange={inputdata}
          />

          {infoRequired.landmarks == "empty" && (
            <span className="error__tx">Landmarks is Required</span>
          )}
        </FormGroup>
  
      </Form>
      <div className="breakdown_1_time" style={{ marginBottom:"2rem" }}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <h6 className="common_tech mb-2">Installation</h6>
                            <div className="d-grid custom_tech" style={{ gap: "10px" }}>
                                <div className='date_component d-grid'>
                                    <h6 className="dis_label">Date</h6>
                                    <DatePicker
                                        selected={installationDate}
                                        value={userInfo.delivery}
                                        onChange={(date) => setInstallationDate(date)}
                                        dateFormat="eeee, MMMM d, yyyy"
                                        className="form-control mb-2"
                                    />
                                </div>
                                {/* <div className='time_component d-grid'>
                                    <label className="dis_label">From</label>
                                    <DatePicker
                                        selected={installationTimeFrom}
                                        onChange={(date) => setInstallationTimeFrom(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="From"
                                        dateFormat="h:mm aa"
                                        className="form-control mr-2"
                                    />
                                </div>
                                <div className='time_component d-grid'>
                                    <label className="dis_label">To</label>
                                    <DatePicker
                                        selected={installationTimeTo}
                                        onChange={(date) => setInstallationTimeTo(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="To"
                                        dateFormat="h:mm aa"
                                        className="form-control"
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="common_tech mb-2">Breakdown</h6>
                            <div className="d-grid custom_tech" style={{ gap: "10px" }}>
                                <div className='date_component d-grid'>
                                    <label className="dis_label">Date</label>
                                    <DatePicker
                                        selected={breakdownDate}
                                        value={userInfo.pickup}
                                        onChange={(date) => setBreakdownDate(date)}
                                        dateFormat="eeee, MMMM d, yyyy"
                                        className="form-control mb-2"
                                    />
                                </div>
                                {/* <div className='time_component d-grid'>
                                    <label className="dis_label">From</label>
                                    <DatePicker
                                        selected={breakdownTimeFrom}
                                        onChange={(date) => setBreakdownTimeFrom(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="From"
                                        dateFormat="h:mm aa"
                                        className="form-control mr-2"
                                    />
                                </div>
                                <div className='time_component d-grid'>
                                    <label className="dis_label">To</label>
                                    <DatePicker
                                        selected={breakdownTimeTo}
                                        onChange={(date) => setBreakdownTimeTo(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="To"
                                        dateFormat="h:mm aa"
                                        className="form-control"
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                  <button
                    className="buy__btn auth__btn w-100"
                    onClick={placeOrder}
                  >
                    Shipping
                  </button>
                </div>
    </>
  
  }

export default OrderShippingAddress;
