import React, { useState } from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";
import PlanModal from "../PlanModal";

export const PlanModalContext = React.createContext(null);

const Layout = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <PlanModalContext.Provider value={{ show: show, setShow: setShow }}>
        <Header />
        <Routers />
        <PlanModal show={show} handleClose={handleClose} setShow={setShow} />
        <Footer />
      </PlanModalContext.Provider>
    </>
  );
};

export default Layout;
