import React from "react";
import "../styles/cart.css";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Col, Container, Row } from "reactstrap";
import { motion } from "framer-motion";
import { cartActions } from "../redux/slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProviders";
import { toast } from "react-toastify";

const Cart = (props) => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const { orderList } = useStateContext();
  const isLogin = useSelector((state) => state.user.isLogin);
  const guestInfo = useSelector((state) => state.user.guestInfo);
  const navigate = useNavigate();

  const checkout = () => {
    
    if (isLogin || guestInfo !== null) {
      props.action(1)

    } else {
      toast.error('Login First')
      navigate("/login")
    }
  }



 

  return (
    <Helmet>
      {/* <CommonSection title="Shopping Cart" /> */}
      <Container>
        <Row className="mt-1 mb-2">
          <Col lg="12" md="12">
            <div>
            {/* {props.activeItem === 1 ? null : (
                cartItems.length > 0 && props.activeItem !== 1 ? (
                  <button className="buy__btn w-100" onClick={checkout}>
                     Checkout
                  </button>
                ) : null
              )} */}
              
              
              
              {/* <button className="btn btn-outline-primary btn-block mt-2">
                <Link to="/all/product" style={{ textDecoration: 'none', color: 'inherit' }}> Add Extra Equipment </Link>
              </button> */}
            </div>
          </Col>
        </Row>
      </Container>
    </Helmet>
  );
};

const Tr = ({ item }) => {
  const dispatch = useDispatch();

  const qtyChange = (qt, id, typ2) => {
    let qty = qt;
    if (typ2 === "plus") {
      qty = qt + 1;
    }
    if (typ2 === "neg") {
      qty = qt - 1;
    }
    let data = { id: id, qty: qty };
    dispatch(cartActions.itemIncDic(data));
  };

  const deleteProduct = () => {
    dispatch(cartActions.deleteItem(item.id));
  };

  return (
    <tr>
      <td>
        <img src={`http://127.0.0.1:8000/${item.imgUrl}`} alt="" />
      </td>
      <td>{item.productName}</td>
      <td>{item.price} </td>
      <td className="qitemst">
        <div className="itemabtr">
          <button
            className="qt_btn bsewd"
            onClick={() => {
              qtyChange(item.quantity, item.id, "neg");
            }}
          >
            -
          </button>
          <div><input type="number" value={item.quantity} className="qtyinput" /></div>


          <button
            className="qt_btn  bsewd"
            onClick={() => {
              qtyChange(item.quantity, item.id, "plus");
            }}
          >
            +
          </button>

        </div>
      </td>
      <td>
        <motion.i
          whileTap={{ scale: 1.2 }}
          onClick={deleteProduct}
          class="ri-delete-bin-line"
        ></motion.i>
      </td>
    </tr>
  );
};

export default Cart;
