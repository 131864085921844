import React, { useState, useRef, useEffect } from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Col, Container, Row } from "reactstrap";
import { useParams,useNavigate} from "react-router-dom";
import products from "../assets/data/products";
import "../styles/product-details.css";
import { motion } from "framer-motion";
import ProductList from "../components/UI/ProductsList";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../redux/slices/cartSlice";
import { toast } from "react-toastify";
import axiosClient from "../axios-client";

import pimg from "../assets/images/arm-chair-02.jpg"
import Carousel from 'react-multi-carousel';
import { getCurrencydata } from "../redux/slices/settingSlice";


import ProductDetailsCart from "../components/UI/ProductDetailsCart";
import { WidthFull } from "@mui/icons-material";
import Spinner from "../components/Spninnerloader/Spinner";


const ProductDetails = () => {
  const [tab, setTab] = useState("desc");
  const reviewUser = useRef("");
  const reviewMsg = useRef("");
  const dispatch = useDispatch("");
  const [rating, setRating] = useState(null);
  const { id } = useParams();
  const [productId,setProductId]=useState(id)
  // setProductId(id)
  let [productDetals, setProductDetails] = useState([]);
  let [productDiscount, setProductDiscount] = useState(0);
  let [relatedProducts, setRelatedProducts] = useState([]);
  let [productImg, setProductImages] = useState([]);
  let [productmainImg, setProductMainImages] = useState('');
  let [showImg, setshowImg] = useState('');
  let [color, setColor] = useState(false);
  let [size, setSize] = useState(false);

  let [choose_color, setchoose_color] = useState('');
  let [choose_size, setchoose_size] = useState('');
  const [loading, setLoading] = useState(true);
  const currencySymbol = useSelector(state => state.setting.currency);
  const navigate=useNavigate();



  const submitHandler = (e) => {
    e.preventDefault();
    const reviewUserName = reviewUser.current.value;
    const reviewUserMsg = reviewMsg.current.value;
    const reviwObj = {
      userName: reviewUserName,
      text: reviewUserMsg,
      rating,
    };
    console.log(reviwObj);
    toast.success("Send Your Reviews");
  };
 
  useEffect(() => {
    setProductId(id)
    changedata(id)
    window.scrollTo(0, 0);
    setColor(false)
    setSize(false)
    dispatch(getCurrencydata());
  },[id]);


  function changedata(id){
    setProductId(id)
  }

  useEffect(() => {
    setLoading(true); 
    dispatch(getCurrencydata());
    axiosClient
      .get(`home/subcategory/product?subCategory_id=1`)
      .then(({ data }) => {
        setRelatedProducts(data.data)
        setLoading(false);
      });
      
  }, []);


  return (
    
    <Helmet title={productDetals.name}>
      {loading?(
        <div className="spinner_loader">
      <Spinner/>
      </div>
    ):(
        <> 
      <ProductDetailsCart id={id}/>
      </>
)}
    </Helmet>
  );
};

export default ProductDetails;
