import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import { Button, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import "../styles/contact.css"
import { toast } from "react-toastify";
import { BsClockFill, BsWhatsapp } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import axiosClient from '../axios-client';


const ContactUs = () => {
    const { aboutus } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [isSending, setIsSending] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [contactUs, setContactUs] = useState('');





    const submitdata = async() => {
        setIsSending(true);
        setName('');
        setEmail('');
        setContact('');
        setMessage('');



        const emailData = {
            name:name,
            email:email,
            message:message,
            contact:contact 
    }
    try {
      const response =   await axiosClient.post(`/contact/email`,emailData,{
            headers: {
                "Content-Type": "application/json",
              },
        });
        console.log("response",response);
    } catch (error) {
        console.log(error);
    }
// console.log("emailData",emailData);
setIsSending(false);
    toast.success('Successfully message send');
    }

    useEffect(() => {
        axiosClient.get('seo/contact').then(({ data }) => {
          setContactUs(data.data);
        });
    
      }, [])


    return (
        <Helmet title={contactUs && contactUs.seo_title}>
            <meta name="description" content={contactUs && contactUs.seo_meta_desc} />
            <meta name="keywords" content={contactUs && contactUs.seo_meta_name} />


            <div class="text-center container">
                <h1 id="contact_h1" class="text-black mb-2 mt-4">Contact Us</h1>
            </div>
            <div class="text-center container">
                <h3 id="contact_h3" class="text-black">Contact Us- Event Production, DJ Equipment, Sound System & Lighting by  AV Rental Service
                </h3>
            </div>
            {/* <Container>
                <div className="row mt-5 mb-5 maindiv">
                    <div className="col-sm-6 locationBackground">
                        <div className='addressinfo'>
                            <div className='infoflex'>
                                <div className='titlewidth'> <span className='icondata'><AiOutlinePhone /></span> <span classNameName='title_tx'>Phone</span> </div>
                                <p className='titleinfowidth'>0179297777</p>
                            </div>

                            <div className='infoflex'>
                                <div className='titlewidth'> <span className='icondata'><AiOutlineMail /></span> <span classNameName='title_tx'>Email</span></div>
                                <p className='titleinfowidth'>dfh@gmail.com</p>
                            </div>
                            <div className='infoflex'>
                                <div className='titlewidth'> <span className='icondata'><AiOutlineEnvironment /></span> <span classNameName='title_tx'>Location</span> </div>
                                <p className='titleinfowidth'>medium-emphasis buttons. They contain actions that are important but aren't the primary</p>
                            </div>


                        </div>
                    </div>
                    <div className="col-sm-6 rightbackground">
                        <div className='coutactinputdiv'>
                            <FormGroup>
                                <Input className='inputtxtsiz' value={name} onChange={ev => setname(ev.target.value)} type="text" name="email" id="exampleEmail" placeholder="Name" required />
                            </FormGroup>
                            <FormGroup>
                                <Input classNameName='inputtxtsiz' value={email} onChange={ev => setemail(ev.target.value)} type="email" name="email" id="exampleEmail" placeholder="Email" />
                            </FormGroup>
                            <FormGroup>
                                <textarea className='form-control inputtxtsiz' value={text} onChange={ev => setptext(ev.target.value)} rows={5} col={20}> </textarea>
                            </FormGroup>


                            <Button type='submit' onClick={submitdata} variant="contained" className='sendbtn'  >
                                Send
                            </Button>

                        </div>



                    </div>
                </div>


            </Container> */}
            <div className="container px-4 py-5">
                <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5">
                    <div className="col-md-12 mx-auto col-lg-6 m-0">
                        <div className="row row-cols-1 row-cols-sm-2 g-4">

                            <div className="col d-flex align-items-start">
                                <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                    <svg className="bi" width="1em" height="1em"> <BiCurrentLocation /></svg>
                                </div>
                                <div>
                                    <h4 className="fw-semibold mb-0 text-body-emphasis"> Location:</h4>
                                    <p className="text-body-secondary">
                                        {/* <b>DJPeoples-Miami</b> */}
                                        <br />
                                        136 NW 36 Street
                                        Miami Fl 33127<br />
                                        <span>Call <a href="tel:305-330-1357" style={{ fontWeight: "500", color: "blue" }}>(305) 330-1357</a></span>
                                    </p>
                                </div>
                            </div>

                            <div className="col d-flex align-items-start">
                                <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                    <svg className="bi" width="1em" height="1em"> <BiCurrentLocation /></svg>
                                </div>
                                <div>
                                    <h4 className="fw-semibold mb-0 text-body-emphasis"> Location:</h4>
                                    <p className="text-body-secondary">
                                        {/* <b>DJPeoples-Miami</b> */}
                                        <br />
                                        E Broward Blvd & N Andrews Ave,
                                        Fort Lauderdale, FL 33301<br />
                                        <span>Call <a href="tel: 954-289-4907" style={{ fontWeight: "500", color: "blue" }}>(305) 967-7422</a></span>
                                    </p>
                                </div>
                            </div>

                            <div className="col d-flex align-items-start">
                                <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                    <svg className="bi" width="1em" height="1em"> <BsClockFill /></svg>
                                </div>
                                <div>
                                    <h4 className="fw-semibold mb-0 text-body-emphasis"> Hours of Operation: </h4>
                                    <p className="text-body-secondary"> Quotes available 24 hours</p>
                                    <p className="text-body-secondary"> Mon - Fri: 24 hours</p>
                                    <p className="text-body-secondary"> SAT: 5AM-11PM</p>
                                    <p className="text-body-secondary"> SUN: Closed</p>
                                </div>
                            </div>
                            <div className="col d-flex align-items-start">
                                <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                    <svg className="bi" width="1em" height="1em"> <MdEmail /></svg>
                                </div>
                                <div>
                                    <h4 className="fw-semibold mb-0 text-body-emphasis">Email: </h4>
                                    <p className="text-body-secondary">marc@marcvane.com</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-12 mx-auto col-lg-6">

                        <form className="p-md-3 border rounded-0 bg-white" id="contact_us">
                            <div className="text-center container mb-3">
                                <h2 className="text-black fw-bold">Request a Quote
                                </h2>
                            </div>
                            <div className="text-center container mb-4">
                                <h5 className="text-black">Please complete our online quote request to recieve quotation
                                </h5>
                            </div>
                            <div className="form-floating mb-3">
                                <Input className='inputtxtsiz' style={{ borderRadius: "10px" }} value={name} onChange={(e) => setName(e.target.value)} type="text" name="name" id="exampleName" placeholder="Name" required />
                                <label for="floatingInput">Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <Input classNameName='inputtxtsiz' style={{ borderRadius: "10px", border:"1px solid #ced4da"}} value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="exampleEmail" placeholder="Email" />
                                <label for="floatingInput">Email </label>
                            </div>
                            <div className="form-floating mb-3">
                                <Input className='inputtxtsiz' style={{ borderRadius: "10px" }} value={contact} onChange={(e) => setContact(e.target.value)} type="number" name="contact" id="exampleEmail" placeholder="Conatct" required />
                                <label for="floatingInput">Contact</label>
                            </div>
                            
                            <div className="form-floating mb-3">
                                <textarea className='form-control inputtxtsiz' style={{ borderRadius: "10px", height: "160px" }} value={message} rows="10" onChange={(e) => setMessage(e.target.value)}> </textarea>
                                <label for="floatingPassword">Input your Massage </label>
                            </div>
                            <button type='submit' onClick={submitdata} variant="contained" style={{ borderRadius: "10px" }} className="w-100 btn btn-lg btn-primary"  disabled={isSending}>
                        {isSending ? 'Sending...' : 'Send'}
                            </button>
</form>
                    </div>
                </div>
            </div>
        </Helmet >
    )
};

export default ContactUs;