import React from "react";
import { PayPalScriptProvider} from "@paypal/react-paypal-js";
import PayPalButton from "./PayPalButton";

const PayPal = () => {

  

  const initialOptions = {
    clientId: "test",
    currency: "USD",
    intent: "capture",
};
  return (
    <div style={{width:"100%"}}>
      <PayPalScriptProvider  options={initialOptions} >
        <PayPalButton/>
        </PayPalScriptProvider>
    </div>
  );
};

export default PayPal;
