import React from 'react'
import { PayPalButtons,usePayPalScriptReducer, BraintreePayPalButtons } from '@paypal/react-paypal-js'

function PayPalButton() {
    const [{ isPending, options }, dispatch] = usePayPalScriptReducer();

    const changeCurrency = (currency) => {
        dispatch({
          type: "resetOptions",
          value: {
            ...options,
            currency: currency,
          },
        });
    
        dispatch({ type: "setLoadingStatus", value: "pending" });
      };

  return (
    <div>
      {isPending ? (
        <div>Loading PayPal buttons...</div>
      ) : (
        <PayPalButtons
        //   style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "10.00", // Replace with the actual amount
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              alert(`Transaction completed by ${details.payer.name.given_name}`);
            });
          }}
          onError={(err) => {
            console.error("PayPal Checkout Error", err);
          }}
        />
 
      )}
 <button className="btn_dev" onClick={() => changeCurrency("EUR")}> EUR</button>
 <button className="btn_pick" onClick={() => changeCurrency("USD")}> USD</button>

    </div>
  )
}

export default PayPalButton
