// src/components/CarouselSkeleton.jsx

import React from 'react';
import './CarouselSkeleton.css';

const CarouselSkeleton = () => {
    return (
        <div className="carousel-skeleton">
            <div className="skeleton-image"></div>
            <div className="skeleton-text"></div>
            <div className="skeleton-text"></div>
            <div className="skeleton-button"></div>
        </div>
    );
};

export default CarouselSkeleton;