import React, { createContext } from 'react';
import "./App.css";
import Layout from "./components/Layout/Layout";
import { QueryClient, QueryClientProvider } from "react-query";

const BuildPlanModal = createContext({
  show: false,
  setShow: () => { }
});


function App() {
  let queryclient = new QueryClient()
  return (
    <>
      <QueryClientProvider client={queryclient}>
        <BuildPlanModal.Provider value={false}>
          <Layout />
        </BuildPlanModal.Provider>
      </QueryClientProvider>
    </>
  );
};

export default App;
