import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import { useParams } from 'react-router-dom';
import AboutImg from '../assets/images/img/Ads-01-01.jpg';
import '../styles/about.css';
import OrderInfo from '../components/UI/OrderInfo';
import LogoSlider from './LogoSlider';
import axiosClient from '../axios-client';
import Djpeoples1 from '../../src/assets/images/djpeoples1.jpg'


const AboutUs = () => {
    const [aboutUs,setAboutUs]= useState('');
    const { aboutus } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    useEffect(() => {
        axiosClient.get('seo/about').then(({ data }) => {
          setAboutUs(data.data);
        });
    
      }, [])
    // {aboutus}
    return (
        <Helmet title={aboutUs && aboutUs.seo_title}>
        <meta name="description" content={aboutUs && aboutUs.seo_meta_desc} />
        <meta name="keywords" content={aboutUs && aboutUs.seo_meta_name} />

            <CommonSection title={aboutUs && aboutUs.seo_title} />
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <h6 className="culture mb-1">A “Cultural Think Tank”</h6>
                        <p className="about_text mb-2">DJ Peoples Group is “Cultural Think Tank” that provides DJ Services and Products especially tailored to Brands, Retailors, and Private Events.</p>
                        <p className="about_text mb-2">As the pioneering brand behind the DJ movement that began in late 1999, DJ Peoples has created the market for DJs outside the nightclub realm. DJPeoples heads the industry by providing turn-key products and services that allow companies to enter the DJ realm credibly.</p>
                        <p className="about_text">Based out of Miami Florida, DJPeoples services cities nationwide including, New York, Las Vegas and LA giving companies the ability to replicate their brand message in hundreds of markets on the same day. With over 6,000 events per year, DJPeoples roster boasts the largest network of stylish and fashionable DJs that provide our clients with a wide array of music that fits the brand message, creating an instant connection between the brand and customer. DJPeoples Products and Service are provided by our Sub Brands which have been created to service particular Markets and Client types.</p>
                    </div>
                    <div className="col-md-6 col-sm-12" id='imagetabs'>
                        <img className='about_img' src={Djpeoples1} alt="dj peoples" />
                    </div>
                </div>
                <div className='row'> 
                    <LogoSlider />
                </div>
            </div>
        </Helmet >
    )
};

export default AboutUs;
