import React, { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import "../styles/checkout.css";
import { AiOutlineRight } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import OrderShipping from "./Checkout/OrderShipping";
import { shippingCost, getCurrencydata } from '../redux/slices/settingSlice';
import OrderStepList from "../components/UI/OrdeStepList";
import { Link, useNavigate } from "react-router-dom";
import Cart from "./Cart";
import axiosClient from "../axios-client";
import OrderShippingAddress from "../components/UI/OrderShippingAddress";
import ProductsList from "../components/UI/ProductsList";
import DatePicker from "react-datepicker";
import FullInfo from "./FullInfo";
import CostBreakdown from "./CostBreakdown";


const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};




const Checkout = () => {
  
  const dispatch = useDispatch();
  const totalQty = useSelector((state) => state.cart.totalQuantity);
  const subtotal = useSelector((state) => state.cart.totalAmount);
  const shippingCostget = useSelector((state) => state.setting.shippingCost);
  const validSubtotal = isNaN(subtotal) ? 0 : parseFloat(subtotal);
  const validShippingCost = isNaN(shippingCostget) ? 0 : parseFloat(shippingCostget);
  
  const shippingCostdata = validSubtotal > 0 ? validShippingCost : 0;
  const totalAmount = validSubtotal + shippingCostdata;
  const cartItems = useSelector((state) => state.cart.cartItems);
  const currency = useSelector((state) => state.setting.currency);
  const [shippingInfo, setShippingInfo] = useState([]);
  const basepath = useSelector((state) => state.setting.basepath);
  let [relatedProducts, setRelatedProducts] = useState([]);


  const [installationDate, setInstallationDate] = useState(new Date());
  const [installationTimeFrom, setInstallationTimeFrom] = useState(new Date());
  const [installationTimeTo, setInstallationTimeTo] = useState(new Date());

  const [breakdownDate, setBreakdownDate] = useState(new Date());
  const [breakdownTimeFrom, setBreakdownTimeFrom] = useState(new Date());
  const [breakdownTimeTo, setBreakdownTimeTo] = useState(new Date());


  const [activeItem, setActiveItem] = useState(0);
  const [checkoutValue,setCheckoutValue] = useState(0)
  const [cartitem, setCartitem]= useState('')
  const [wait, setWait] = useState(false)

  const navigate = useNavigate();
  const shippingAddress = (address) => {
    setShippingInfo(address)
    setActiveItem(2);
  }

//console.log("shippingCostdata",subtotal);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCurrencydata())
  }, [])

  useEffect(() => {
    dispatch(getCurrencydata());

    var pid = [];
    cartItems.map(data => {
      pid = [...pid, data.id];
    });

    

    axiosClient
      .get(`home/subcategory/product/related?productlist=${[pid]}`)
      .then(({ data }) => {
        setRelatedProducts(data)
        console.log('data6', data)
      });

  }, []);


  const orderStepAction = (data) => {
    setActiveItem(data)
  }

  const checkoutgetValue = (data) =>{
    setCheckoutValue(data)
  }


  useEffect(() => {

  }, [activeItem]);

  //console.log("activeItem",activeItem);


  const getcartData = async () => {

    try {
      setWait(true)
      await axiosClient.get('user/cart').then(resopnse => {
        setCartitem(resopnse.data)
        setWait(false)
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getcartData();
  }, []);


 const activeButton = (() => {
    switch (activeItem) {
      case 0:
        return <Cart action={orderStepAction}  />;
      case 1:
        return <OrderShippingAddress address={shippingAddress} />;
      case 2:
        return <OrderShipping shippingInfo={shippingInfo} />;
      default:
        return null;
    }
  })();


  
  return (
    
    <Helmet title="Checkout">
      <section className="mb-4 pt-2">
        <OrderStepList action={orderStepAction}  activitem={activeItem} />
        <Container>
          <div className="bg-white shadow-sm p-4 checkout_mainCard">
            <Row>
              <div className=" col-lg-8 col-md-12 cutomerinfost checkoutcard-div-style">
                {
                  activeItem == 0  && <FullInfo  wait={wait}cartItems={cartItems} cartitem={cartitem} basepath={basepath} totalAmount={totalAmount} action={orderStepAction} /> || activeItem == 1 && <OrderShippingAddress address={shippingAddress} /> || activeItem == 2 && <OrderShipping cartitem={cartitem} shippingInfo={shippingInfo} />
                }
              </div>
                

              <Col lg="4" md="12" className="margincart">
              {<CostBreakdown checkoutvalue={checkoutgetValue} cartitem={cartitem} activitem={activeItem}  action={orderStepAction} totalAmount={totalAmount}/>}
              </Col>
            </Row>
          </div>
        </Container>
      </section>

    </Helmet>

  );
};

export default Checkout;
