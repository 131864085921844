import React, { useState, useEffect, } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './Categrois.css';
import Subcategory from './Subcategory';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Config } from '../Config';


function Categoris({ categoryListdata }) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if (categoryListdata && categoryListdata.data) {
      // Simulate an asynchronous data fetch with a timeout
      setTimeout(() => {
        setData(categoryListdata.data);
        setLoading(false);
      }, 1000); // Adjust the timeout duration as needed
    }
  }, [categoryListdata]);

  const handleAccordionToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  const truncateName = (name, maxLength = 13) => {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, maxLength) + "...";
  };


 //console.log(data);
  
  return (
    <>
      <div className='Heading'>
        <h1>CATEGORY</h1>
      </div>
      {loading ? (
        <div>
       <div className='loader'>
       <div className='box_stucture'>
         <div className='image_section'>
         <div className='image_structure'></div>
         <div className='text_title'></div>
         </div>
         <div className='userlistitem'>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
         </div>
         <div className='sfsdf'>
           <div className='jfhcffhcf _b'></div>
           <div className='user_btn _s'></div>
         </div>
       </div>
       
     </div>
     <div className='loader'>
       <div className='box_stucture'>
         <div className='image_section'>
         <div className='image_structure'></div>
         <div className='text_title'></div>
         </div>
         <div className='userlistitem'>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
         </div>
         <div className='sfsdf'>
           <div className='jfhcffhcf _b'></div>
           <div className='user_btn _s'></div>
         </div>
       </div>
       
     </div>
     <div className='loader'>
       <div className='box_stucture'>
         <div className='image_section'>
         <div className='image_structure'></div>
         <div className='text_title'></div>
         </div>
         <div className='userlistitem'>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
         </div>
         <div className='sfsdf'>
           <div className='jfhcffhcf _b'></div>
           <div className='user_btn _s'></div>
         </div>
       </div>
       
     </div>
     <div className='loader'>
       <div className='box_stucture'>
         <div className='image_section'>
         <div className='image_structure'></div>
         <div className='text_title'></div>
         </div>
         <div className='userlistitem'>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
         </div>
         <div className='sfsdf'>
           <div className='jfhcffhcf _b'></div>
           <div className='user_btn _s'></div>
         </div>
       </div>
       
     </div>
     <div className='loader'>
       <div className='box_stucture'>
         <div className='image_section'>
         <div className='image_structure'></div>
         <div className='text_title'></div>
         </div>
         <div className='userlistitem'>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
           <div className='userlist-item _s '></div>
         </div>
         <div className='sfsdf'>
           <div className='jfhcffhcf _b'></div>
           <div className='user_btn _s'></div>
         </div>
       </div>
       
     </div>
     </div>
      ) : (
        <Accordion>
          {Array.isArray(data) && data.map((item, index) => (
            <div className='main_con' key={index}>
              <div className="mt-2 kjdhfwe" id="category_accordian">
                <Accordion.Item id="accordian_item" eventKey={index} onClick={() => handleAccordionToggle(index)}>
                  <Accordion.Header id="accordion-header_home" className={activeIndex === index ? 'active' : ''}>
                    <div className='main_div'>
                    <div className="userlist">{item.name}</div>
                      {/* <div className='dcwrweew' style={{ display: "flex" }}>
                        <div className='img_1'>
                          <img loading='lazy' src={Config.baseUrl + "/" + item.image} alt={item.name} />
                        </div>
                        <div className="userlist">{item.name}</div>
                      </div> */}
                      <div className='userlistitem'>

                        {Array.isArray(item.product) && item.product.slice(0, 4).map((list)=>{
                          return(
                            <img className='product_imge' src={Config.baseUrl + "/" + list.image_path}/>
                          )
                        })}

                        {/* {item.subcategory.slice(0, 4).map((subItem, subIndex) => (
                          <div className="userlist-item" key={subIndex}>
                            <div>{subItem.name}</div>
                          </div>
                        ))} */}
                      </div>
                      <div className='sfsdf'>
                        <div className='jfhcffhcf'>
                          <FormatListBulletedIcon />
                        </div>
                        <div className='user_btn'>
                          <button style={{ border: "none" }}>View Product</button>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Subcategory  category={item} subcategorydata={item.product} />
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            </div>
          ))}
        </Accordion>
      )}
    </>
  );
}

export default Categoris;
